import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass({
      [_ctx.$style['label']]: true,
      h: true,
      [_ctx.$style['has-value']]: _ctx.inputVal,
      [_ctx.$style['is-' + _ctx.type]]: _ctx.$style['is-' + _ctx.type],
      [_ctx.$style['is-disabled']]: _ctx.disabled,
    })
  }, [
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.type !== 'textarea')
      ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({ key: 0 }, _ctx.computedProps, {
          type: _ctx.type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal) = $event))
        }), null, 16, _hoisted_2)), [
          [_vModelDynamic, _ctx.inputVal]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("textarea", _mergeProps({ key: 1 }, _ctx.computedProps, {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputVal) = $event))
        }), null, 16)), [
          [_vModelText, _ctx.inputVal]
        ])
  ], 2))
}
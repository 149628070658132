
import { defineComponent } from "vue";
import TextInput from "@/components/elements/TextInput.vue";
import CTA from "@/components/elements/CTA.vue";

import axios from "axios";

export default defineComponent({
  components: { TextInput, CTA },
  data() {
    return {
      success: false,
      loading: false,
      full_name: "",
      email: "",
      phone: "",
      message: "",
    };
  },
  computed: {
    data() {
      return this.$store.state.contact;
    },
  },
  methods: {
    submit(e: Event): void {
      e.preventDefault();
      this.loading = true;

      axios
        .post(
          "/backend?action=contact",
          {
            full_name: this.full_name,
            email: this.email,
            phone: this.phone,
            message: this.message,
          },
          {
            baseURL: document.location.protocol + "//" + document.location.host,
          }
        )
        .then((resp) => {
          if (!resp.data.success) {
            alert("Error.");
            console.error(resp.data);
          } else {
            this.success = true;
            this.full_name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
          }
          this.loading = false;
        });
    },
  },
});

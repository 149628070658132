// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes ContactView_strongAppear_kfBcR{0%{width:0}to{width:100%}}@keyframes ContactView_strongAppear_kfBcR{0%{width:0}to{width:100%}}.ContactView_intro-text_wzacT{padding-top:1em;padding-bottom:3em;font-size:1.25em}.ContactView_intro-text_wzacT p{margin-bottom:1em}.ContactView_intro-text_wzacT p:last-child{margin-bottom:0}.ContactView_intro-text_wzacT p strong{font-weight:700;display:inline-block;z-index:0;position:relative}.ContactView_intro-text_wzacT p strong:after{content:\"\";display:block;background:var(--primary-color);height:.75em;width:0;z-index:-1;transform:translate3d(.2em,.1em,0);position:absolute;bottom:0;left:0;-webkit-animation-name:ContactView_strongAppear_kfBcR;animation-name:ContactView_strongAppear_kfBcR;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-delay:3s;animation-delay:3s;-webkit-animation-timing-function:var(--ease-in-out-cubic);animation-timing-function:var(--ease-in-out-cubic);-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards}.ContactView_intro-text_wzacT p em{font-style:italic}.ContactView_wrapper_bUqvP{display:flex;align-items:center;justify-content:center;width:100%;min-height:100%;position:relative}.ContactView_wrapper_bUqvP .ContactView_inner_ubFbJ{padding:2em;max-width:760px;width:100%;text-align:center}.ContactView_wrapper_bUqvP h1{text-transform:uppercase;font-weight:900;margin-bottom:.5em}.ContactView_wrapper_bUqvP h1:after{content:\"\";height:.1em;width:4em;display:block;background:var(--primary-color);margin:auto;margin-top:.25em}.ContactView_wrapper_bUqvP p{font-weight:600}.ContactView_cta_AeYHk{margin-top:1em;font-size:1.5em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro-text": "ContactView_intro-text_wzacT",
	"strongAppear": "ContactView_strongAppear_kfBcR",
	"wrapper": "ContactView_wrapper_bUqvP",
	"inner": "ContactView_inner_ubFbJ",
	"cta": "ContactView_cta_AeYHk"
};
export default ___CSS_LOADER_EXPORT___;


import { defineComponent } from "vue";

import OrderForm from "@/components/functional/OrderForm.vue";
import CTA from "@/components/elements/CTA.vue";

import ContactView from "@/views/ContactView.vue";

export default defineComponent({
  data() {
    return {
      scroll_position: 0,
      drag_position: 0,
      display_request_form: false,
      display_contact_form: false,
      can_display_contact_form: false,
      display_contact_form_active: false,
      is_vertical: window.innerHeight > window.innerWidth,
    };
  },
  components: { OrderForm, ContactView, CTA },
  watch: {
    display_request_form(to) {
      this.$store.commit("setHasOverlay", to);
    },
    display_contact_form(to) {
      this.$store.commit("setHasOverlay", to);
      if (to) {
        this.can_display_contact_form = true;
        setTimeout(() => {
          this.display_contact_form_active = true;
        }, 50);
      } else {
        this.display_contact_form_active = false;
        setTimeout(() => {
          this.can_display_contact_form = false;
        }, 500);
      }
    },
  },
  computed: {
    loaded() {
      return this.$store.state.galleries_loaded;
    },

    album(): {
      name: string;
      slug: string;
      primary_color?: string;
      display_order_form?: boolean;
      description: string;
      gallery: { width: number; height: number }[];
    } {
      const slug = this.$route.params.slug;
      const album = this.$store.getters.getGalleryBySlug(slug);

      return album;
    },

    album_positions() {
      const positions: { width: number; left: number; top: number }[] = [];
      let left_pos = 500; // 500 because we have the "desc-container"

      this.album.gallery.forEach((item: { width: number; height: number }) => {
        let width = 500;
        // if (index === 0 || (index%5 === 0)) {
        width = (window.innerHeight / item.height) * item.width;
        // }

        positions.push({
          width: width,
          left: left_pos,
          top: 0,
        });

        left_pos += width;
      });

      return positions;
    },

    desc_opacity(): number {
      const pos = -this.scroll_position;
      let opacity = pos - 300;
      opacity = opacity / 300;

      if (opacity < 0) opacity = 0;
      if (opacity > 1) opacity = 1;
      return 1 - opacity;
    },

    total_width() {
      let width = 0;
      this.album_positions.forEach((position) => {
        width += position.width;
      });

      width += 400;

      return width;
    },

    gallery_scroll_position(): number {
      return this.scroll_position;
    },

    gallery_mouse_offset(): number {
      return this.scroll_position > -500
        ? 0
        : this.$store.state.mouse_position.left * -0.05;
    },
  },
  methods: {
    touchStart(e: TouchEvent) {
      if (this.is_vertical) return;
      this.drag_position = e.touches[0].clientX;
    },
    touchEnd(e: TouchEvent) {
      if (this.is_vertical) return;
      this.drag_position = 0;
    },
    touchMove(e: TouchEvent) {
      if (this.is_vertical) return;
      if (!this.drag_position) return;

      const delta = e.touches[0].clientX - this.drag_position;
      this.setScrollPosition(delta);

      this.drag_position = e.touches[0].clientX;
    },
    mouseDown(e: MouseEvent) {
      if (this.is_vertical) return;
      this.drag_position = this.$store.state.mouse_position.left;
    },
    mouseMove(e: MouseEvent) {
      if (this.is_vertical) return;
      if (!this.drag_position) return;

      const delta = this.$store.state.mouse_position.left - this.drag_position;
      this.setScrollPosition(delta);

      this.drag_position = this.$store.state.mouse_position.left;
    },
    mouseUp(e: MouseEvent) {
      if (this.is_vertical) return;
      this.drag_position = 0;
    },
    mouseWheel(e: WheelEvent) {
      if (this.is_vertical) return;

      e.preventDefault();
      e.stopPropagation();
      if (this.drag_position) return;

      let delta = e.deltaX;
      if (e.deltaY !== 0) {
        delta = e.deltaY;
      }

      this.setScrollPosition(-delta);
    },
    // mouseDown(e: DragEvent) {
    //   console.log(e);
    //   e.stopPropagation();
    // },
    // mouseUp(e: DragEvent) {
    //   console.log(e);
    //   e.stopPropagation();
    // },
    setScrollPosition(delta: number) {
      delta = delta * 1.25;

      this.scroll_position += delta;
      if (this.scroll_position > 0) {
        this.scroll_position = 0;
      }

      const max_width = this.total_width - window.innerWidth;

      if (this.scroll_position < -max_width) {
        this.scroll_position = -max_width;
      }
    },
  },
});

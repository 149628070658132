
import { defineComponent } from "vue";

export default defineComponent({
  props: ["album"],
  data() {
    return {
      is_clicked: false,
    };
  },
  computed: {
    is_loading() {
      return this.$props.album ? false : true;
    },
    is_active() {
      return this.$route.params.slug === this.album.slug ? true : false;
    },
    offset() {
      let top = 0;
      let left = 0;

      const ratio = 0.7;

      top =
        (this.$store.state.mouse_position.top - window.innerHeight / 2) *
          ratio +
        ((window.innerHeight / 2) * ratio) / 6;

      left =
        (this.$store.state.mouse_position.left - window.innerWidth / 2) *
          ratio +
        ((window.innerWidth / 2) * ratio) / 6;

      return {
        top: top,
        left: left,
      };
    },
  },
});


import { defineComponent } from "vue";

import CTA from "@/components/elements/CTA.vue";
import TextInput from "@/components/elements/TextInput.vue";

import axios from "axios";

export default defineComponent({
  props: ["album", "onSuccess"],
  components: { TextInput, CTA },

  data() {
    return {
      selected_images: [],
      loading: false,
      success: false,
      full_name: "",
      email: "",
      phone: "",
      support: "",
    } as {
      selected_images: (string | null)[];
      full_name: string;
      email: string;
      phone: string;
      support: string;
      loading: boolean;
      success: boolean;
    };
  },
  computed: {
    data() {
      return this.$store.state.order_form;
    },
  },
  methods: {
    selectImage(e: Event, thumb: string) {
      e.preventDefault();
      e.stopPropagation();

      if (this.selected_images.includes(thumb)) {
        this.selected_images = this.selected_images
          .map((item) => {
            if (item === thumb) {
              return null;
            }
            return item;
          })
          .filter((n) => n);
      } else {
        this.selected_images.push(thumb);
      }
    },

    submit(e: Event) {
      e.preventDefault();
      axios
        .post(
          "/backend?action=order",
          {
            full_name: this.full_name,
            email: this.email,
            phone: this.phone,
            support: this.support,
            selected_images: this.selected_images,
            album_name: this.album.name,
          },
          {
            baseURL: document.location.protocol + "//" + document.location.host,
          }
        )
        .then((resp) => {
          if (!resp.data.success) {
            alert("Error.");
            console.error(resp.data);
          } else {
            this.success = true;
            this.full_name = "";
            this.email = "";
            this.phone = "";
            this.selected_images = [];
          }
          this.loading = false;
        });
    },
  },
});

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmallLoader_loader_NURlt{display:inline-block;position:relative;width:1em;height:1em}.SmallLoader_loader_NURlt span{display:inline-block;position:absolute;left:.1em;width:.2em;background:var(--main-foreground-color);-webkit-animation:SmallLoader_loader_NURlt 1.2s cubic-bezier(0,.5,.5,1) infinite;animation:SmallLoader_loader_NURlt 1.2s cubic-bezier(0,.5,.5,1) infinite}.SmallLoader_loader_NURlt span:first-child{left:.1em;-webkit-animation-delay:-.24s;animation-delay:-.24s}.SmallLoader_loader_NURlt span:nth-child(2){left:.4em;-webkit-animation-delay:-.12s;animation-delay:-.12s}.SmallLoader_loader_NURlt span:nth-child(3){left:.7em;-webkit-animation-delay:0;animation-delay:0}@-webkit-keyframes SmallLoader_loader_NURlt{0%{top:.1em;height:.8em}50%,to{top:.3em;height:.4em}}@keyframes SmallLoader_loader_NURlt{0%{top:.1em;height:.8em}50%,to{top:.3em;height:.4em}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "SmallLoader_loader_NURlt"
};
export default ___CSS_LOADER_EXPORT___;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_CTA = _resolveComponent("CTA")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style['order-form']),
    onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
  }, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.album.name), 1),
    (_ctx.data.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style['description']),
          innerHTML: _ctx.data.text
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['steps'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['step'])
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.data.step_1_label ?? "Choose the pictures you want"), 1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['gallery-list'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.album.gallery, (item, index) => {
            return (_openBlock(), _createElementBlock("button", {
              onClick: ($event: any) => (_ctx.selectImage($event, item.thumb)),
              key: index,
              class: _normalizeClass({
              [_ctx.$style['is-selected']]: _ctx.selected_images.includes(item.thumb),
            })
            }, [
              _createElementVNode("img", {
                src: item.thumb
              }, null, 8, _hoisted_3)
            ], 10, _hoisted_2))
          }), 128))
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['step'])
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.data.step_2_label ?? "Give me your contact information"), 1),
        _createVNode(_component_TextInput, {
          required: true,
          type: "text",
          modelValue: _ctx.full_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.full_name) = $event)),
          label: "Full name",
          disabled: _ctx.loading,
          autocomplete: "password"
        }, null, 8, ["modelValue", "disabled"]),
        _createVNode(_component_TextInput, {
          required: true,
          type: "email",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
          label: "E-mail address",
          disabled: _ctx.loading,
          autocomplete: "password"
        }, null, 8, ["modelValue", "disabled"]),
        _createVNode(_component_TextInput, {
          disabled: _ctx.loading,
          type: "tel",
          modelValue: _ctx.phone,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
          label: "Phone number",
          autocomplete: "password"
        }, null, 8, ["disabled", "modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['step'])
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.data.step_3_label ?? "Go go go !"), 1),
        _createVNode(_component_CTA, {
          onClick: _ctx.submit,
          loading: _ctx.loading,
          disabled: !(_ctx.email && _ctx.full_name),
          class: _normalizeClass(_ctx.$style['button']),
          type: "submit"
        }, {
          default: _withCtx(() => [
            (_ctx.success)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.data.sent_label ?? "Thank you!") + " 🎉", 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.data.submit_label ?? "Request prints"), 1))
          ]),
          _: 1
        }, 8, ["onClick", "loading", "disabled", "class"])
      ], 2)
    ], 2)
  ], 34))
}
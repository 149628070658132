
import { defineComponent } from "vue";

import axios from "axios";

import MenuButton from "@/components/elements/MenuButton.vue";
import LogoText from "@/components/elements/LogoText.vue";

interface GalleryItem {
  directus_files_id: {
    location: string;
    tags: [];
    description: string | null;
    data: {
      thumbnails: { key: string; url: string }[];
    };
    width: number;
    height: number;
  };
}

interface Gallery {
  name: string;
  description: string;
  slug: string;
  display_order_form?: boolean;
  primary_color?: string;
  gallery: GalleryItem[];
  featured_image: GalleryItem;
  randomize?: boolean;
  thumbnails_mode?: boolean;
}

interface Thumbnail {
  key: string;
  url: string;
}

export default defineComponent({
  components: {
    LogoText,
    MenuButton,
  },
  computed: {
    pointer_position() {
      return this.$store.state.mouse_position;
    },
    pointer_style(): string | null {
      if (["BUTTON", "A"].includes(this.$store.state.hover_tag))
        return "point-focus";

      if (
        this.pointer_position.left < 10 ||
        this.pointer_position.top < 10 ||
        this.pointer_position.left > window.innerWidth - 10 ||
        this.pointer_position.top > window.innerHeight - 10
      )
        return "invisible";

      if (
        this.is_menu_opened ||
        this.$store.state.has_overlay ||
        this.is_homepage
      )
        return "point";

      if (this.$route.name === "gallery") return "drag";

      return "invisible";
    },
    has_custom_pointer(): boolean {
      if (!matchMedia("(pointer:fine)").matches) return false;
      if (window.innerHeight > window.innerWidth) return false;

      if (
        !this.loaded ||
        (this.is_menu_opened && ["A"].includes(this.$store.state.hover_tag))
      )
        return false;

      if (this.$store.state.has_overlay) return false;

      if (
        this.is_menu_opened ||
        this.$route.name === "gallery" ||
        this.is_homepage
      )
        return true;

      return false;
    },
    is_homepage(): boolean {
      return this.$route?.name === "home";
    },
    image_size(): string {
      if (window.innerWidth <= 800 && window.innerHeight <= 800) {
        return "800";
      } else if (window.innerWidth <= 1200 && window.innerHeight <= 1200) {
        return "1200";
      } else if (window.innerWidth <= 1600 && window.innerHeight <= 1600) {
        return "1600";
      } else if (window.innerWidth <= 2000 && window.innerHeight <= 2000) {
        return "2000";
      } else if (window.innerWidth <= 2400 && window.innerHeight <= 2400) {
        return "2400";
      } else {
        return "3000";
      }
    },
  },
  methods: {
    goHome(e: Event) {
      e.preventDefault();
      this.switch_homepage = true;
      setTimeout(() => {
        this.loaded = false;

        setTimeout(() => {
          this.$router.push("/");
          this.loaded = true;
          setTimeout(() => {
            this.switch_homepage = false;
          }, 500);
        }, 500);
      }, 50);
    },
    loadSingletons() {
      axios
        .get(
          "items/homepage?fields=background_image.data,intro_text,cta_button_label"
        )
        .then((resp) => {
          const info = resp.data.data[0];
          let background_image = "";

          info.background_image.data.thumbnails.forEach(
            (thumbnail: Thumbnail) => {
              if (thumbnail.key === "nj-" + this.image_size) {
                background_image = thumbnail.url;
              }
            }
          );

          this.$store.commit("setHomepage", {
            background_image: background_image,
            intro_text: info.intro_text,
            cta_button_label: info.cta_button_label,
          });
        });

      axios
        .get("items/contact?fields=title,content,submit_label,sent_label")
        .then((resp) => {
          this.$store.commit("setContact", resp.data?.data[0]);
        });

      axios
        .get(
          "items/order_form?fields=text,step_1_label,step_2_label,step_3_label,submit_label,sent_label"
        )
        .then((resp) => {
          this.$store.commit("setOrderForm", resp.data?.data[0]);
        });
    },
    loadGalleries() {
      axios
        .get(
          "items/gallery?fields=name,slug,randomize,thumbnails_mode,primary_color,description,display_order_form,gallery.directus_files_id.width,gallery.directus_files_id.height,gallery.directus_files_id.data.thumbnails,gallery.directus_files_id.description,gallery.directus_files_id.tags,gallery.directus_files_id.location"
        )
        .then((resp) => {
          const galleries = resp.data?.data.map((gallery: Gallery) => {
            let pictures = gallery.gallery.map(
              (item: GalleryItem, index: number) => {
                let url = "";
                let thumb = "";
                let min = "";

                item.directus_files_id.data.thumbnails.forEach(
                  (thumbnail: Thumbnail) => {
                    if (thumbnail.key === "nj-" + this.image_size) {
                      url = thumbnail.url;
                    }
                    if (thumbnail.key === "nj-thumb") {
                      thumb = thumbnail.url;
                    }
                    if (thumbnail.key === "nj-min") {
                      min = thumbnail.url;
                    }
                  }
                );

                if (!index) {
                  axios.get(url);
                  axios.get(min);
                }

                return {
                  url: url,
                  thumb: thumb,
                  min: min,
                  location: item.directus_files_id.location,
                  description: item.directus_files_id.description,
                  tags: item.directus_files_id.tags,
                  width: item.directus_files_id.width,
                  height: item.directus_files_id.height,
                };
              }
            );

            if (gallery.randomize) {
              pictures = pictures.sort(() => Math.random() - 0.5);
            }

            return {
              name: gallery.name,
              description: gallery.description,
              slug: gallery.slug,
              display_order_form: gallery.display_order_form,
              primary_color: gallery.primary_color,
              gallery: pictures,
              featured_image: gallery.featured_image || pictures[0],
            };
          });

          this.$store.commit("setGalleries", galleries);
          this.$store.commit("setGalleriesLoaded", true);

          this.loaded = true;
        });
    },
    handleMouseMove(event: MouseEvent) {
      var eventDoc, doc, body;
      event = event || window.event;

      if (event.pageX == null && event.clientX != null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        eventDoc =
          (event.target && (event.target as any).ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        this.$store.commit("setMousePosition", {
          left:
            event.clientX +
            ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
            ((doc && doc.clientLeft) || (body && body.clientLeft) || 0),
          top:
            event.clientY +
            ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
            ((doc && doc.clientTop) || (body && body.clientTop) || 0),
        });
      } else {
        this.$store.commit("setMousePosition", {
          top: event.pageY,
          left: event.pageX,
        });
      }

      if (event.target)
        this.$store.commit(
          "setHoverTag",
          (event.target as HTMLElement).tagName
        );
    },
  },
  watch: {
    loaded(to: boolean, from: boolean) {
      if (!from && to) {
        setTimeout(() => {
          this.show_loader = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.loadGalleries();
    this.loadSingletons();

    document.onmousemove = this.handleMouseMove;

    let resize_timeout = 0;
    let last_width = 0;
    let last_height = 0;
    window.onresize = () => {
      if (resize_timeout !== 0) {
        clearTimeout(resize_timeout);
      }
      resize_timeout = setTimeout(() => {
        const trigger_ratio = 0.2;
        const width_ratio = last_width / window.innerWidth;
        const height_ratio = last_height / window.innerHeight;
        if (
          width_ratio > 1 + trigger_ratio ||
          width_ratio < 1 - trigger_ratio ||
          height_ratio > 1 + trigger_ratio ||
          height_ratio < 1 - trigger_ratio
        ) {
          this.refresh_increment++;

          last_width = window.innerWidth;
          last_height = window.innerHeight;
        }
      }, 500);
    };
  },
  data() {
    return {
      switch_homepage: false,
      is_menu_opened: false,
      is_pointer_down: false,
      loaded: false,
      show_loader: true,
      refresh_increment: 0,
    };
  },
});

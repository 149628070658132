import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_CTA = _resolveComponent("CTA")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['wrapper'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['inner'])
        }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.data.title), 1),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['intro-text'])
          }, [
            _createElementVNode("p", {
              class: "h",
              innerHTML: _ctx.data.content
            }, null, 8, _hoisted_1)
          ], 2),
          _createElementVNode("form", {
            autocomplete: "off",
            onSubmit: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, [
            _createVNode(_component_TextInput, {
              disabled: _ctx.loading,
              autocomplete: "password",
              type: "text",
              modelValue: _ctx.full_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.full_name) = $event)),
              label: "Full name",
              required: true
            }, null, 8, ["disabled", "modelValue"]),
            _createVNode(_component_TextInput, {
              disabled: _ctx.loading,
              autocomplete: "password",
              type: "email",
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              label: "E-mail address",
              required: true
            }, null, 8, ["disabled", "modelValue"]),
            _createVNode(_component_TextInput, {
              disabled: _ctx.loading,
              autocomplete: "password",
              type: "tel",
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
              label: "Phone number"
            }, null, 8, ["disabled", "modelValue"]),
            _createVNode(_component_TextInput, {
              disabled: _ctx.loading,
              type: "textarea",
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
              label: "Message",
              required: true
            }, null, 8, ["disabled", "modelValue"]),
            _createVNode(_component_CTA, {
              class: _normalizeClass(_ctx.$style['cta']),
              disabled: !(_ctx.full_name && _ctx.email && _ctx.message),
              loading: _ctx.loading,
              type: "submit",
              onClick: _ctx.submit
            }, {
              default: _withCtx(() => [
                (_ctx.success)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.data.sent_label || "Message sent!") + " 🎉", 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.data.submit_label || "Submit"), 1))
              ]),
              _: 1
            }, 8, ["class", "disabled", "loading", "onClick"])
          ], 32)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
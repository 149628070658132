import { createApp } from "vue";

import VueLazyLoad from "vue3-lazyload";

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";

axios.defaults.baseURL = "https://content.njphotographie.be/nj";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueLazyLoad);
app.use(PerfectScrollbar);

app.mount("#app");


import { defineComponent } from "vue";

import Logo from "@/components/elements/LogoText.vue";
import CTA from "@/components/elements/CTA.vue";

export default defineComponent({
  components: { Logo, CTA },
  computed: {
    is_loaded(): boolean {
      return this.$store.state.galleries_loaded && this.data;
    },
    data() {
      return this.$store.state.homepage;
    },
  },
  methods: {
    openMenu() {
      (
        document.querySelector("#main_menu button") as HTMLButtonElement
      ).click();
    },
    makeHighlights() {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = this.data?.intro_text;
      const highlights = tmp.querySelectorAll("strong");

      const tmp2 = document.createElement("DIV");
      tmp2.innerHTML = this.intro_text;

      const existing_highlights: string[] = [];
      tmp2.querySelectorAll("strong").forEach((item: HTMLElement) => {
        existing_highlights.push(item.innerText);
      });

      highlights.forEach((highlight) => {
        if (!existing_highlights.includes(highlight.innerText))
          this.intro_text = this.intro_text.replace(
            highlight.innerText,
            "<strong>" + highlight.innerText + "</strong>"
          );
      });
    },
    makeIntroText() {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = this.data?.intro_text;
      const string = tmp.textContent || tmp.innerText || "";
      const split = string.split("");

      const letter_add_delay = 60;

      for (let i = 0; i < split.length; i++) {
        setTimeout(() => {
          this.intro_text += split[i];
          this.makeHighlights();
        }, i * letter_add_delay + (Math.random() - 0.5) * ((letter_add_delay * 9) / 10));
      }
    },
  },
  watch: {
    is_loaded(to) {
      if (to) {
        setTimeout(this.makeIntroText, 3600);
      }
    },
  },
  data() {
    return { intro_text: "" };
  },
  mounted() {
    if (this.is_loaded) {
      setTimeout(this.makeIntroText, 3600);
    }
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallLoader = _resolveComponent("SmallLoader")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({
      h: true,
      [_ctx.$style['cta']]: true,
      [_ctx.$style['is-loading']]: _ctx.loading,
      [_ctx.$style['has-delay-' + _ctx.delay]]: _ctx.delay && _ctx.$style['has-delay-' + _ctx.delay],
    })
  }, [
    _createElementVNode("span", null, [
      _createElementVNode("span", null, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_SmallLoader, { key: 0 }))
          : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ])
    ])
  ], 2))
}

import { defineComponent } from "vue";

export default defineComponent({
  props: [
    "label",
    "disabled",
    "modelValue",
    "type",
    "autocomplete",
    "required",
  ],
  emits: ["update:modelValue"],
  computed: {
    inputVal: {
      get() {
        let val = this.$props.modelValue;
        return val;
      },
      set(val: string) {
        this.$emit("update:modelValue", val);
      },
    },
    computedProps() {
      let remove_props = [
        "modelValue",
        "error",
        "modelvalue",
        "value",
        "type",
        "size",
      ];

      let new_props: Record<string, string> = {};

      Object.keys(this.$props).forEach((key: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (this.$props as any)[key];
        if (!remove_props.includes(key) && value) {
          new_props[key] = value;
        }
      });

      return new_props;
    },
  },
});

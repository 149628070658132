import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass({
      [_ctx.$style['item']]: true,
      [_ctx.$style['is-loading']]: _ctx.is_loading,
      [_ctx.$style['is-active']]: _ctx.is_active,
      [_ctx.$style['is-clicked']]: _ctx.is_clicked,
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.is_clicked = true)),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.is_clicked = false))
  }, {
    default: _withCtx(() => [
      (!_ctx.is_loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style['label'] + ' h')
            }, [
              _createTextVNode(_toDisplayString(_ctx.album.name) + " ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style['fx'])
              }, _toDisplayString(_ctx.album.name), 3)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (_ctx.album?.featured_image?.thumb)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.album.featured_image.thumb,
            style: _normalizeStyle({
        transform:
          'translate3d(' + _ctx.offset.left + 'px, ' + _ctx.offset.top + 'px, 0)',
      })
          }, null, 12, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
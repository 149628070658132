<template>
  <span :class="$style['loader']">
    <span></span>
    <span></span>
    <span></span>
  </span>
</template>

<style lang="scss" module>
@use "sass:math";

$size: 1em;
.loader {
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;
}
.loader span {
  display: inline-block;
  position: absolute;
  left: math.div($size, 10);
  width: math.div($size, 10) * 2;
  background: var(--main-foreground-color);
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader span:nth-child(1) {
  left: math.div($size, 10);
  animation-delay: -0.24s;
}
.loader span:nth-child(2) {
  left: math.div($size, 10) * 4;
  animation-delay: -0.12s;
}
.loader span:nth-child(3) {
  left: math.div($size, 10) * 7;
  animation-delay: 0;
}
@keyframes loader {
  0% {
    top: math.div($size, 10);
    height: math.div($size, 10) * 8;
  }
  50%,
  100% {
    top: math.div($size, 10) * 3;
    height: math.div($size, 10) * 4;
  }
}
</style>

import { defineComponent } from "vue";

import MenuItem from "@/components/menus/PrimaryMenu/MenuItem.vue";

export default defineComponent({
  props: ["stateChange"],
  mounted() {
    setTimeout(() => {
      this.hidden = false;
    }, 1000);
  },
  data() {
    return {
      hidden: true,
      active: false,
      opened: false,
      can_display: false,
      is_animating: false,
      is_leaving: false,
      is_closing: false,
      animate_timeout: 0,
    };
  },
  computed: {
    offset() {
      let top = 0;
      let left = 0;

      if (!matchMedia("(pointer:fine)").matches) {
        return {
          top: top,
          left: left,
        };
      }

      const ratio = 0.01;

      top =
        (this.$store.state.mouse_position.top - window.innerHeight / 2) *
          ratio +
        ((window.innerHeight / 2) * ratio) / 2;

      left =
        (this.$store.state.mouse_position.left - window.innerWidth / 2) *
          ratio +
        ((window.innerWidth / 2) * ratio) / 2;

      return {
        top: top,
        left: left,
      };
    },
    albums() {
      return this.$store.state.galleries;
    },
  },
  methods: {
    menuClick(e: Event) {
      if ((e.target as HTMLElement).tagName.toUpperCase() === "A") {
        this.is_leaving = true;
      }
      this.active = false;
    },
  },
  watch: {
    active(to: boolean, from: boolean) {
      if (this.$props.stateChange) {
        this.$props.stateChange(to);
      }

      if (this.animate_timeout !== 0) {
        clearTimeout(this.animate_timeout);
      }

      this.is_animating = true;
      if (!from && to) {
        this.is_leaving = false;
        this.is_closing = false;
        this.can_display = true;
        this.animate_timeout = setTimeout(() => {
          this.opened = true;

          this.is_animating = false;
        }, 100);
      } else {
        this.is_closing = true;
        this.opened = false;
        this.animate_timeout = setTimeout(() => {
          this.can_display = false;
          this.is_animating = false;
        }, 2000);
      }
    },
  },
  components: { MenuItem },
});

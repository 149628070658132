import { createStore } from "vuex";

let mouse_moving_timeout: number | null = null;

export default createStore({
  state() {
    return {
      homepage: null,
      contact: null,
      order_form: null,
      galleries: null,
      galleries_loaded: false,
      mouse_position: { top: 0, left: 0 },
      mouse_is_moving: false,
      has_overlay: false,
      hover_tag: null,
    };
  },
  getters: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getGalleryBySlug: (state: any) => (slug: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return state.galleries?.find((gallery: any) => gallery.slug === slug);
    },
  },
  mutations: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setHomepage(state: any, homepage: any) {
      state.homepage = homepage;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setContact(state: any, contact: any) {
      state.contact = contact;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOrderForm(state: any, order_form: any) {
      state.order_form = order_form;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGalleries(state: any, galleries: any) {
      state.galleries = galleries;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGalleriesLoaded(state: any, galleries_loaded: boolean) {
      state.galleries_loaded = galleries_loaded;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setMousePosition(
      state: any,
      mouse_position: { top: number; left: number }
    ) {
      state.mouse_position = mouse_position;
      state.mouse_is_moving = true;
      if (mouse_moving_timeout !== null) {
        clearTimeout(mouse_moving_timeout);
        mouse_moving_timeout = null;
      }
      mouse_moving_timeout = setTimeout(() => {
        state.mouse_is_moving = false;
      }, 500);
    },
    setHoverTag(state: any, hover_tag: string) {
      state.hover_tag = hover_tag;
    },
    setHasOverlay(state: any, has_overlay: boolean) {
      state.has_overlay = has_overlay;
    },
  },
  actions: {},
  modules: {},
});

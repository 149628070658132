// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuItem_item_TvcYL{display:flex;align-items:center;justify-content:center;margin-bottom:1em}@media(max-width:540px){.MenuItem_item_TvcYL{font-size:.9em}}.MenuItem_item_TvcYL *{pointer-events:none}.MenuItem_item_TvcYL .MenuItem_label_AD6AW{font-weight:900;font-size:3em;display:block;transition:color .3s,-webkit-text-stroke-color .3s,text-shadow .3s;color:transparent;-webkit-text-stroke:1.5px var(--main-foreground-color);position:relative}.MenuItem_item_TvcYL .MenuItem_label_AD6AW .MenuItem_fx_c3heX{position:absolute;top:0;left:0;-webkit-text-stroke-color:var(--primary-color);font-style:italic;transition:color .3s,opacity .3s;opacity:0}.MenuItem_item_TvcYL.MenuItem_is-clicked_HBc7X img{opacity:0!important}@media(pointer:fine){.MenuItem_item_TvcYL:hover{cursor:none}.MenuItem_item_TvcYL:hover .MenuItem_label_AD6AW .MenuItem_fx_c3heX{opacity:1}}.MenuItem_item_TvcYL img{opacity:0;pointer-events:none;height:240px;width:320px;max-width:1000px;-o-object-fit:cover;object-fit:cover;position:fixed;top:0;right:0;z-index:-1;display:block;transition:transform .4s ease-out,opacity .25s}@media(pointer:fine){.MenuItem_item_TvcYL:hover img{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "MenuItem_item_TvcYL",
	"label": "MenuItem_label_AD6AW",
	"fx": "MenuItem_fx_c3heX",
	"is-clicked": "MenuItem_is-clicked_HBc7X"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoText_logo_ChXSZ{font-weight:800;color:var(--main-foreground-color);line-height:.72em}.LogoText_logo_ChXSZ:after{content:\".\"}.LogoText_logo_ChXSZ.LogoText_is-small-without-dot_qsHJY:after{content:\"\";display:none}.LogoText_logo_ChXSZ span{margin-right:1em}.LogoText_logo_ChXSZ.LogoText_is-small-without-dot_qsHJY span,.LogoText_logo_ChXSZ.LogoText_is-small_rb8xk span{margin-right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "LogoText_logo_ChXSZ",
	"is-small-without-dot": "LogoText_is-small-without-dot_qsHJY",
	"is-small": "LogoText_is-small_rb8xk"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderForm_description_LffJw{padding-top:1em;padding-bottom:5em;font-size:1.25em}.OrderForm_order-form_r4E_7{display:flex;flex-direction:column}.OrderForm_order-form_r4E_7 .OrderForm_button_A1Mca{font-size:1.5em;margin-top:1em}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz{position:relative;margin-bottom:7em}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:first-child:before{content:\"1\"}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:first-child:before,.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(2):before{-webkit-text-stroke:1px var(--main-foreground-color);position:absolute;color:transparent;top:-.5em;right:.5em;font-size:6em;font-style:italic;opacity:.33333}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(2):before{content:\"2\"}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(3):before{content:\"3\"}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(3):before,.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(4):before{-webkit-text-stroke:1px var(--main-foreground-color);position:absolute;color:transparent;top:-.5em;right:.5em;font-size:6em;font-style:italic;opacity:.33333}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:nth-child(4):before{content:\"4\"}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz:last-child{margin-bottom:0}.OrderForm_steps_Os0Og .OrderForm_step_h8Mvz h2{text-transform:uppercase;font-size:1.2em}.OrderForm_gallery-list_NwhIU{display:flex;flex-wrap:wrap;margin:0 -.5em;margin-right:0}.OrderForm_gallery-list_NwhIU>button{padding:.5em;transition:all .2s;position:relative}.OrderForm_gallery-list_NwhIU>button:before{content:\"\";position:absolute;background:var(--main-foreground-color);top:.5em;left:.5em;bottom:.5em;right:.5em;z-index:-1}.OrderForm_gallery-list_NwhIU>button img{width:100px;height:100px;-o-object-fit:cover;object-fit:cover;transition:all .2s}.OrderForm_gallery-list_NwhIU>button.OrderForm_is-selected_EXDBu img{opacity:.7;transform:scale(.9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "OrderForm_description_LffJw",
	"order-form": "OrderForm_order-form_r4E_7",
	"button": "OrderForm_button_A1Mca",
	"steps": "OrderForm_steps_Os0Og",
	"step": "OrderForm_step_h8Mvz",
	"gallery-list": "OrderForm_gallery-list_NwhIU",
	"is-selected": "OrderForm_is-selected_EXDBu"
};
export default ___CSS_LOADER_EXPORT___;

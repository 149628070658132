import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoText = _resolveComponent("LogoText")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.switch_homepage || (!_ctx.is_homepage && _ctx.show_loader))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({
      [_ctx.$style['loader']]: true,
      [_ctx.$style['is-loaded']]: _ctx.loaded,
      [_ctx.$style['is-primary']]: 0 && _ctx.switch_homepage,
    })
        }, [
          (!_ctx.switch_homepage)
            ? (_openBlock(), _createBlock(_component_LogoText, {
                key: 0,
                type: "small",
                class: _normalizeClass({
        [_ctx.$style['logo']]: true,
      })
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
      [_ctx.$style['app']]: true,
      [_ctx.$style['has-custom-cursor']]: _ctx.has_custom_pointer,
    }),
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.is_pointer_down = true)),
      onMouseup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.is_pointer_down = false))
    }, [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style['header'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style['primary'])
        }, [
          _createElementVNode("a", {
            href: "/",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
          }, [
            _createVNode(_component_LogoText, {
              type: "small",
              class: _normalizeClass({
              [_ctx.$style['logo']]: true,
              [_ctx.$style['is-hidden']]: _ctx.is_homepage,
            })
            }, null, 8, ["class"])
          ])
        ], 2),
        _createElementVNode("div", null, [
          _createVNode(_component_MenuButton, {
            id: "main_menu",
            stateChange: 
            (state) => {
              _ctx.is_menu_opened = state;
            }
          
          }, null, 8, ["stateChange"])
        ])
      ], 2),
      _createElementVNode("main", {
        class: _normalizeClass(_ctx.$style['main'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({
          [_ctx.$style['inner-element']]: true,
        })
        }, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: 
            _ctx.$route.name +
            '_' +
            (_ctx.$route.params.slug ? _ctx.$route.params.slug : '') +
            '_' +
            _ctx.refresh_increment
          
          }))
        ], 2)
      ], 2),
      _createElementVNode("footer", {
        class: _normalizeClass(_ctx.$style['footer'])
      }, null, 2)
    ], 34),
    (_ctx.has_custom_pointer && _ctx.pointer_style)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({
      [_ctx.$style['pointer']]: true,
      [_ctx.$style['is-pointer-down']]: _ctx.is_pointer_down,
      [_ctx.$style['is-' + _ctx.pointer_style]]:
        _ctx.pointer_style && _ctx.$style['is-' + _ctx.pointer_style],
    }),
          style: _normalizeStyle({
      transform:
        'translate3d(' +
        _ctx.pointer_position.left +
        'px,' +
        _ctx.pointer_position.top +
        'px,0)',
    })
        }, null, 6))
      : _createCommentVNode("", true)
  ], 64))
}